:root {
  /* New */
  /* --main: rgb(255, 84, 106); */
  --hot-red: rgb(244, 95, 54);
  --hot-red-hover: rgb(212, 73, 35);
  --second-deep: rgb(86, 148, 247);
  --main: rgb(103, 161, 254);
  --main-hover: rgb(83, 137, 224);
  --second: rgb(103, 161, 254);
  --second-hover: rgb(83, 137, 224);
  --main-flat: rgb(228, 71, 92);
  --main-light: rgb(255, 118, 136);
  --blue: rgb(103, 161, 254);
  --blue-dark: rgb(73, 132, 227);
  --light-blue: rgb(221, 234, 254);
  --mid-blue: rgb(186, 206, 240);
  --mid-dark-blue: rgb(156, 178, 215);
  --mid-darker-blue: rgb(113, 135, 175);
  --mid-light-blue: rgb(205, 220, 241);
  --dark-blue: rgb(8, 27, 59);
  --gold: rgb(242, 205, 39);
  --gold-medium: rgb(230, 200, 69);
  --gold-light: rgb(255, 230, 116);
  --light-gold: rgb(255, 248, 218);
  --dark-gold: rgb(55, 46, 6);
  /* blue */
  --dark-blue: rgb(20, 27, 61);
  --dark-blue-2: rgb(49, 58, 106);
  --dark-blue-3: rgb(60, 70, 125);
  /* console */
  --consoleBackground: #2b3139;
  --consoleHighlight: #49505b;
  --consolePurple: rgb(203, 157, 250);
  --consolePurpleBright: rgb(188, 123, 252);
  --consoleBlue: #8cb0ff;
  --consoleLightBlue: #84d4f5;
  --consoleLightGreen: #c2e78f;
  --consoleRed: #ff6075;
  --consoleOrangeRed: #fe8e6f;
  --consoleOrange: #ffdb6e;
  --consoleWhite: rgb(235, 235, 235);
  --consoleComment: #909ba8;
  /* neutral */
  --default-button: rgb(230, 230, 230);
  --default-button-hover: rgb(207, 207, 207);

  --paper: white;
  --background: #f6f6f6;
  --lightest: #f6f6f6;
  --soft-border: #d5d3d3;
  --border: rgb(170, 170, 170);
  --mid-border: rgb(200, 200, 200);
  --light-border: #e1e1e1;
  --dark-border: #d7d7d7;
  --secondary-dark: #103b5c;
  --dark: #0f141a;
  --dark-hover: #282f38;
  --text: #434343;
  --grey: #d1d1d1;
  --medium: rgb(62, 68, 66);
  --medium-dark: rgb(58, 63, 62);

  --highlight: rgb(221, 234, 254);
  --darklight: #434d5a;


  /*  */
  --deep-darkest-blue: #181823;
  --deep-dark-blue: #1c1c39;
  --deep-blue: #35357e;
  --deep-blue-hover: #1f1f4c;
  --deep-blue-blue: #3333aa;
  --gold: rgb(255, 204, 0);
  --gold: rgb(231, 185, 0);
  --gold-hover: rgb(203, 163, 1);
  /* --light-blue: #ebf2ff; */


  --dark-green: rgb(46, 133, 46);
  --green: rgb(62, 157, 62);
  --light-green: rgb(241, 255, 241);
  --dark-orange: rgb(201, 78, 25);
  --light-orange: rgb(255, 243, 237);
  --dark-yellow: rgb(156, 133, 20);
  --light-yellow: rgb(255, 250, 237);
  --dark-purple: rgb(81, 59, 194);
  --light-purple: rgb(246, 243, 255);
  --dark-red: rgb(212, 72, 72);
}

.flex {
  display: flex;
}

.flex-ai--center {
  align-items: center;
}

.ai-center {
  align-items: center;
}

.as-center {
  align-self: center;
}

.jc-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.container {
  position: relative;
  max-width: 1300px;
  margin: auto;
}

.page .container {
  padding-left: 20px;
  padding-right: 20px;
}

.Danger {
  color: var(--hot-red);
}

button {
  cursor: pointer;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
}

.mainButton,
.secondButton,
.yellowButton,
.outlineButton,
.darkButton {
  padding: 1.2rem;
  min-width: 8rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.mainButton {
  color: white;
  background-color: var(--main);
  /* background-color: var(--deep-blue); */
  border: 1px solid var(--main);
}

.yellowButton {
  color: var(--dark);
  background-color: var(--gold);
  border: 1px solid var(--gold);
}

.yellowButtonHover {
  color: var(--dark);
  background-color: var(--gold-hover);
  border: 1px solid var(--gold-hover);
}

.mainButton:hover {
  background-color: var(--main-hover);
  border: 1px solid var(--main-hover);
}

.secondButton {
  background-color: var(--default-button);
  border: 1px solid var(--default-button);
  color: var(--dark)
}

.secondButton:hover {
  background-color: var(--default-button-hover);
  border: 1px solid var(--default-button-hover);
}

.darkButton {
  background-color: var(--dark);
  color: white;
}

.darkButton:hover {
  background-color: var(--dark-hover);
}

.outlineButton {
  background-color: inherit;
  border: 1px solid var(--border);
  color: var(--dark)
}

.outlineButton:hover {
  background-color: rgb(245, 245, 245);
}

body {
  font-family: 'Roboto', monospace;
  font-size: 1.3rem;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: var(--dark);
}

a:hover {
  color: var(--blue)
}

.articleBody a {
  color: var(--blue-dark)
}

.articleBody a:hover {
  color: var(--blue)
}

.page {
  min-height: 100vh;
  padding-top: 60px;
  background-color: var(--background);
}

.blastPage {
  background-color: var(--paper);
}

.darkPage {
  background-color: var(--darkPaper);
}

h1 {
  font-size: 2.3rem;
}

::-moz-selection {
  padding: 5px;
  background: var(--highlight);
}

::selection {
  padding: 5px;
  background: var(--highlight);
  width: fit-content;
}

.footer ::-moz-selection {
  background: var(--darklight);
}

.footer ::selection {
  background: var(--darklight);
  /* color: var(--dark); */
}

.infoCard ::selection {
  background-color: var(--mid-blue);
}

.infoCard ::-moz-selection {
  background-color: var(--mid-blue);
}

.divider {
  margin-left: .5rem;
  margin-right: .5rem;
}

p,
ol,
ul,
.articleText {
  line-height: 2.4rem;
  font-size: 1.3rem;
  color: var(--text);
}

.xsmallGap {
  min-height: .5rem;
}

.smallGap {
  min-height: 1rem;
}

.mediumGap {
  min-height: 2rem;
}

.medium-pd-top {
  padding-top: 2rem;
}

.bg-white {
  background: white;
}

.inlineCode {
  background-color: rgb(248, 248, 248);
  border: 1px solid var(--soft-border);
  padding-left: 2px;
  padding-right: 2px;
  color: var(--hot-red);
  border-radius: 2px;
  font-size: 1.1rem;
}

pre,
.codeBox {
  z-index: 0 !important;
}

.topLine {
  border-top: 1px solid var(--border);
}

.bottomLine {
  border-bottom: 1px solid var(--border);
}

.bottomLine {
  border-bottom: 1px solid var(--border);
}

textarea {
  max-width: 100%;
  min-width: 100%;
}

.invisible {
  display: none;
}

.bigTitle {
  font-weight: 1000;
  font-size: 4.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mobileOnly {
  display: None !important;
}

.successBox {
  background-color: var(--light-green);
  color: var(--dark-green);
  border: 1px solid var(--green);
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--border);
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: grey;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: grey;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: grey;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: grey;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: rgb(180, 180, 180);
}

@media (max-width: 550px) {
  .bigTitle {
    font-size: 3.5rem;
  }

  .mobileOnly {
    display: block !important;
    ;
  }

  .desktopOnly {
    display: none !important;
    ;
  }

  .mainButton,
  .secondButton,
  .yellowButton {
    font-size: 1rem;
    padding: 1rem;
    font-weight: 600;
  }

  body,
  p,
  ol,
  ul,
  .articleText {
    font-size: 1.2rem;
  }

  p,
  ol,
  ul,
  .articleText {
    line-height: 2.2rem;
  }
}