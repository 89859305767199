.vendors .line1, .vendors .line2, .vendors .line3, .vendors .line4{
    margin: auto;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 1rem;
}
.vendors .btmLine {
    margin-top: 1rem;
}
.vendors .ofTwo, .vendors .ofFour {
    display: none;
}
.vendors {
    overflow-x: auto;
    padding-bottom: 1rem;
    overflow-x: auto;
}

.topicCard{
    background-color: var(--paper);
    border: 1px solid var(--border);
    padding: 1rem;
    display: flex;
    justify-content: center;
    margin-right: 10px;
}
.vendors .topicCard img {
    width: auto;
    height: 2rem;
}
@media (max-width: 750px){
    .vendors .line1, .vendors .line2, .vendors .line3, .vendors .line4{
        margin-top: 1rem;
        gap: 0px;
    }
    .vendors .ofTwo {
        display: none;
    }
}
@media (max-width: 550px) {
    .vendors .line1, .vendors .line2, .vendors .line3, .vendors .line4{
        gap: 0px;
    }
    .vendors .ofThree {
        display: none;
    }
    .vendors .ofFour {
        display: block;
    }
    .vendors .topicCard img {
        height: 2rem;
    }
    .vendors .line3{
        justify-content: left;
    }
}