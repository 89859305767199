.commentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.commentFormBody textarea {
    max-height: 5rem;
    min-height: 5rem;
    margin-bottom: 0px;
}

/* ABSTRACT ME */
.greyButton {
    background-color: rgb(248,248,248);
    border: 1px solid var(--border);
    color: var(--text);
    font-weight: 600;
    font-size: 1.1rem;
    padding: .8rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.greyButton:hover {
    color: var(--main);
    border: 1px solid var(--main);
}

.userIconContainer {
    display: flex;
}
.userIconContainer .commentFormBody {
    flex-grow: 1;
}

.userIconContainer .userIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    margin-top: .5rem;
    margin-right: .5rem;
}

.commentSection hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}