.network {
    /* background-color: rgb(248, 248, 248); */
    border: 2px solid var(--consoleBackground);
    height: 300px;
}
canvas {
    margin: auto;
    display: flex;
}

hr {
    color: var(--border);
    border: 1px solid var(--light-border);
}
.networkComponent .codeBox {
    margin-top: 0rem;
    margin-bottom: 1rem;
}
.networkComponent .codeBox pre{
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    margin-top: 0px;
}

.networkComponent button {
    font-weight: 700;
    padding: 1.5rem;
    display: flex;
    margin-left: auto;
    color: white;
    background-color: var(--second);
    border: 1px solid var(--second-hover);
    font-size: 1.2rem;
}
.networkComponent button:hover {
    background-color: var(--second-hover)
}