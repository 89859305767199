.wave {
    position: absolute;
    height:70rem ;
    top: 0px;
    width: 100%;
    aspect-ratio: 3/2;
    overflow: hidden;
}
.background {
    background-color: var(--background);
}
.landingPage{
    position: relative;
    background-color: var(--background);
}
.landingPage .aboutText {
    margin-bottom: 2rem;
}
.articleCard {
    transition: transform .1s ease-in-out;
}
.articleCard:hover {
    transform: rotate(2deg);
}
.skeleton .articleCard:hover, .articleCard.skeleton:hover {
    transform: rotate(0deg);
}
.landingPage .container{
    padding: 20px;
}

.subTitle {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 2rem;
}
.vendorTitle{
    padding: 10px;
    background-color: var(--deep-blue);
    color: var(--paper);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.landingPage .infoCard{
    border-top: 1px solid var(--mid-blue);
}

.vendorSection{
    margin-top: 5.5rem;
    margin-bottom: 4rem;
}

@media (max-width: 600px){
    .blackBox:hover, .articleCard:hover {
        transform: rotate(0deg);
    }
    .landingPage .aboutContainer{
        padding: 0px;
    }
}