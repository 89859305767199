.codeBox {
    position: relative;
    font-size: .95rem;
    overflow-y: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    transition: margin .1s ease-in-out;
}

.codeBox:hover {
    margin-left: -20px;
    margin-right: -20px;
}

.codeBox.abbreviated {
    max-height: 40rem;
}

.codeBox .copyBtn {
    position: absolute;
    z-index: 10;
    color: grey;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.codeBox .rotate span {
    transform: rotate(270deg);
}

.codeBox .copyBtn span:hover {
    color: rgb(175, 175, 175)
}

.codeBox code {
    white-space: pre;
}

/* @media (max-width: 550px){
    .codeBox code{
        white-space: pre;
    }
} */