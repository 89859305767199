.infoCard {
    color: var(--dark-blue);
    background-color: var(--light-blue);
    padding: 2rem;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, .1);
}

.infoTitle {
    /* margin-top: 0px; */
    display: flex;
    align-items: center;
    color: var(--dark-blue);
}

.infoTitle span {
    margin-right: 1rem;
    font-size: 2rem;
    font-weight: 700;
}

.infoBody {
    margin-bottom: 1rem;
}

@media (max-width: 500px) {
    .infoCard {
        padding: 1rem;
    }

    .infoCard .infoTitle h2,
    .infoCard h2 {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .landingPage .infoCard h2 {
        margin-top: 1rem;
    }

    .landingPage .infoCard {
        padding: 1.5rem;
    }

    .infoBody {
        margin-bottom: 0px;
    }

    .infoBody p {
        margin-bottom: 0px;
        margin-top: 0px;
    }
}