.articleBanner {
    padding: 1rem;
    padding-bottom: 0px;
}

.bannerContent .formTitle {
    font-weight: 900;
    font-size: 2.2rem;
}

.bannerContent .formTitle.subscribed {
    color: var(--dark-green);
}

.bannerContent {
    justify-content: center;
    display: flex;
    justify-content: center;
    border: 1px solid var(--border);
    align-items: center;
    height: 8rem;
}

.bannerContent form {
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bannerContent button {
    font-size: 1.2rem;
    font-weight: 600;
    width: 8rem;
    border: 0px solid var(--gold);
    height: 100%;
}

.bannerContent form input {
    height: 100%;
    min-width: auto;
    margin: 0px;
    margin-right: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 1px solid var(--border);
}

.bannerContent input:focus {
    outline: none !important;
    border: 2px solid var(--second);
}

@media (max-width: 850px) {
    .bannerContent {
        flex-direction: column;
        height: auto;
    }

    .bannerContent .formTitle {
        margin-bottom: 0px;
        margin-right: 0px;
        margin-top: 2rem;
    }

    .bannerContent form {
        width: 100%;
        flex-direction: column;
    }

    .bannerContent form button {
        width: 100%;
        height: auto;
    }

    .bannerContent form input {
        max-width: none;
        margin-bottom: .5rem;
        margin-right: 0px;
        height: 3.8rem;
        width: 100%;
    }
}

@media (max-width: 550px) {
    .bannerContent .formTitle {
        font-size: 2rem;
    }

    .bannerContent form {
        padding: 1rem;
    }
}