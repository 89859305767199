.footer {
    text-align: center;
    padding: 2rem;
    background-color: var(--dark);
    color: var(--border);
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
}

.footer p {
    margin: none;
}

.footer a,
.footer p {
    font-size: 1.1rem;
    color: var(--border);
}

.footer a {
    border: 2px solid var(--border);
    padding: 8px;
    border-radius: 50%;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.footer a:hover {
    color: white;
    border: 2px solid white;
}

.footerLinksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerLinks {
    width: fit-content;
}

.footer .coppyright p {
    margin-top: 0px
}

.footer .coppyright a {
    border: 0px;
    font-size: 1.1rem;
}

.footer .coppyright {
    margin-top: 1.5rem;
}

.footer .contact {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1000px) {
    .footer {
        grid-template-columns: 1fr;
    }
}