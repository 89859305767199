.postFooter .links{
    display: flex;
    margin: auto;
}
.postFooter .links button{
    margin-right: .5rem;
}
.postFooter .links .disabled{
    color: var(--light-border);
    border: 1px solid var(--light-border);
    cursor: default;
}
.postFooter .links .disabled:hover{
    color: var(--light-border);
    border: 1px solid var(--light-border);
    background-color: rgb(248,248,248);
}

.postFooter .links .material-symbols-outlined {
    font-size: 2rem
}
.postNavButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(248,248,248);
    border: 1px solid var(--border);
    color: var(--text)
}
.postFooter .links button {
    padding: 10px;
    min-width: 6rem;
}
.postNavButton .previous{
    transform: rotate(180deg);
}
.postNavButton:hover{
    color: var(--main);
    border: 1px solid var(--main);
}
.postFooter .links .likeButton{
    margin-left: auto;
    margin-right: 0px;
    color: var(--blue-dark);
    border: 1px solid var(--blue-dark);
}
.postFooter .links .likeButton:hover {
    background-color: var(--blue-dark);
    color: var(--consoleWhite);
    border: 1px solid var(--blue-dark);
}