.join .infoContainer {
    margin-bottom: 2rem;
}
.join .loginButtons {
    display: flex;
}
.join .loginButtons .buttons{
    width: 100%;
    display: flex;
    padding-bottom: .5rem;
    align-items: center;
    justify-content: end;
    border-bottom: 1px solid var(--border);
}

.join .loginButtons .email {
    font-weight: 500;
    color: var(--dark);
}
.join .loginButtons button{
    font-size: 1rem;
    padding: .6rem;
    min-width: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.join .loginButtons .signupBtn {
    position: relative;
    border-radius: 50px;
    z-index: 2;
    background-color: var(--dark);
    border: 2px solid white;
    font-weight: 600;
}
.join .signupBtn{
    margin-left: auto;
}

.join .loginButtons:hover {
    .signupBtn {
        background-color: var(--dark-hover);
    }
    .loginBtn {
        background-color: var(--dark-hover);
    }
}

.join .loginButtons .loginBtn{
    color: white;
    background-color: var(--dark);
    border: 2px solid white;
    padding-left: 56px;
    position: relative;
    border-radius: 50px;
    margin-left: -50px;
    font-weight: 600;
    z-index: 1;
    /* border: 0px; */
}