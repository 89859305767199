.articleHeader .authorAvatar {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    width: 3.4rem;
    height: 3.4rem;
    background-color: var(--light-blue);
    color: var(--dark-blue);
    border-radius: 50%;
    margin-right: 1rem;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, .1);
    border: 1px solid var(--mid-darker-blue);
    font-weight: 500;
}

.authorInfo {
    margin-bottom: 1rem;
}

.articleHeader .authorInfo {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.authorDate {
    color: var(--text);
}

.authorDate .subscribe {
    /* color: rgb(36, 169, 100); */
    color: var(--dark-green);
    border: 1px solid var(--dark-green);
    background-color: var(--light-green);
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 600;
    margin-left: 1rem;
}

.authorDate .subscribe:hover {
    color: var(--green);
    border: 1px solid var(--green);
}

.authorName {
    margin-bottom: .6rem;
    color: var(text);
    font-weight: 600;
}

.articleHeader .interactionIcons {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    color: grey;
    align-self: flex-end;
}

.articleHeader ul {
    list-style: none;
    padding: 0px;
    display: flex;
}

.chip {
    line-height: 26px;
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    /* background-color: rgb(248,248,248); */
    border: 1px solid grey;
    color: grey;
    margin-right: .4rem;
    white-space: nowrap;
}

.articleHeader {
    border-bottom: 1px solid var(--border);
    margin-bottom: 2rem;
    padding-bottom: 1rem;
}

.articleHeader h1 {
    /* max-width: 550px; */
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 3.8rem;
}

.postArticle .infoCard {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.postArticle .infoCard h2 {
    font-size: 1.5rem;
    margin-bottom: .5rem;
    margin-top: 1rem;
}

.articleBody .articleTitle {
    color: var(--medium);
    font-size: 2rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.article {
    /* max-width: 50rem; */
    max-width: 710px;
    margin: auto;
}

.article .container {
    position: relative;
}

.postTags {
    overflow-x: auto;
    margin-bottom: 0px;
    margin-top: 1rem;
}

.postTags li {
    color: var(--text);
    font-size: 1.2rem;
}

.postTags::-webkit-scrollbar {
    display: none;
}

.postTags {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.articleComponentContainer {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.article img {
    max-width: 100%;
    height: auto;
}

.articleBody .highlight {
    background-color: var(--highlight);
}

.articleBody .quote {
    border-left: 4px solid var(--main);
    padding-left: 1rem;
}

@media (max-width: 650px) {
    .postArticle .container {
        padding: 0px;
    }
}

@media (max-width: 550px) {
    .articleHeader h1 {
        font-size: 2.8rem;
        margin-top: 1rem;
    }

    .articleHeader .postTags {
        display: none;
    }

    .articleBody .articleTitle {
        margin-top: 1rem;
    }
}

@media (max-width: 400px) {
    .articleHeader .interactionIcons {
        display: none;
    }
}