.postContainer {
    padding-bottom: 8rem;
}

.postArticle .container {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: var(--paper);
    border: 1px solid var(--border);
    border-bottom: 0px;
    margin-top: 2rem;
}

.article-gap {
    box-sizing: border-box;
    border-left: 1px solid var(--border);
    border-right: 1px solid var(--border);
}

.emailPrompt .container {
    background-color: var(--paper);
    border: 1px solid var(--border);
    border-top: 0px;
}

@media (max-width: 650px) {
    .emailPrompt .container {
        padding: 0px;
        border: 0px;
    }

    .article-gap .container {
        padding: 0px;
        border: 0px;
    }

    .postArticle .container {
        background-color: var(--background);
        border: 0px;
    }

    .article-gap {
        background-color: var(--paper);
        border-left: 0px;
        border-right: 0px;
    }

    .small-blast-white {
        background-color: var(--paper);
    }

    .emailPrompt .container {
        background-color: var(--paper);
    }

    .postArticle .container {
        background-color: var(--paper);
    }
}

@media (max-width: 550px) {
    .postArticle .container {
        margin-top: 1rem;
    }
}