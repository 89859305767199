/* POST LIST */

.gridNav .subTitle .navArrows {
    margin-left: auto;
    display: flex;
}

.gridNav .subTitle .navArrows .material-symbols-outlined {
    font-size: 2rem
}

.gridNav .subTitle .navArrows .previous {
    transform: rotate(180deg);
}

.gridNav .subTitle .navArrows button {
    color: rgb(160, 160, 160);
    margin-left: .5rem;
    background-color: rgb(242, 242, 242);
    border: 1px solid var(--border);
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.gridNav .subTitle .navArrows button:hover {
    border: 1px solid var(--main);
    color: var(--main);
}

.gridNav .subTitle .direct {
    transform: rotate(180deg);
    margin-right: 1rem;
    font-size: 2rem;
}

.gridNav .subTitle {
    font-size: 1.6rem;
}

.gridNav .subTitle .navArrows .navInfo {
    font-size: 1.4rem;

}

.gridNav .subTitle .navArrows .disabled {
    color: var(--mid-border);
    cursor: default;
    border: 1px solid var(--mid-border);
}

.gridNav .subTitle .navArrows .disabled:hover {
    color: var(--mid-border);
    cursor: default;
    border: 1px solid var(--mid-border);
}

.articles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    /* overflow-x: hidden; */
    padding-bottom: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.articleCard {
    box-sizing: border-box;
    border: 1px solid var(--border);
    min-width: 0;
    position: relative;
    height: 255px;
    background-color: var(--paper);
    box-shadow: 5px 5px 20px rgba(0, 0, 0, .1);

}

.articleCard .cardBody {
    padding-top: 2rem;
}

.articleCard .cardHeader {
    padding-bottom: .2rem;
}

.articleCard .cardHeader {
    border-bottom: 1px solid var(--border);
    color: var(--consoleWhite);
}

.articleCard .cardInfo {
    box-sizing: border-box;
    margin-left: 1rem;
    /* text-align: center; */
    display: flex;
    align-items: center;
}

.articleCard h1 {
    margin: 1rem;
}

.articleCard a {
    margin: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
}

.articleCard:hover {
    cursor: pointer;
    border: 2px solid var(--second);
    margin: 0px;
}

.articleCard .articleTitle {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.7rem;
}

.articleCard .articleTitle:hover {
    cursor: pointer;
}

.skeleton .articleCard,
.articleCard.skeleton {
    background-color: var(--background);
}

.skeleton .articleCard:hover,
.articleCard.skeleton:hover {
    border: 1px solid var(--border);
    cursor: default;
}

.articleCard a:hover {
    color: var(--dark);
}

.articleCard .articleTitle .titleText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.articleCard .articleDate,
.articleCard .articleAuthor,
.articleCard .separator {
    margin: 0px;
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--text);
    margin-right: 1rem;
}

.articleCard .articleDescription {
    padding-left: 1rem;
    padding-right: 1rem;
    color: var(--text);
    line-height: 2.2rem;
}

.articleCard hr {
    margin: 0rem;
    padding: .4rem;
    border: 0px;
}

.articleCardFooter {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: grey;
    position: absolute;
    bottom: 1.2rem;
    right: 1rem;
    z-index: 10;
}

.articleCardFooter span {
    margin-left: 6px;
}

.emptyset {
    font-size: 1.6rem;
    ;
    font-weight: 800;
}

@media (max-width: 1150px) {
    .articles {
        grid-template-columns: 1fr 1fr;
    }

    .articleCard.skeleton {
        display: none;
    }
}

@media (max-width: 750px) {
    .articles {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 600px) {
    .gridNav .subTitle .navArrows button {
        padding: 7px;
    }

    .gridNav .subTitle .direct {
        font-size: 1.8rem;
    }

    .gridNav .subTitle {
        font-size: 1.4rem;
    }

    .gridNav .subTitle .navArrows .navInfo {
        font-size: 1rem
    }

    .gridNav .subTitle .navArrows .material-symbols-outlined {
        font-size: 1.4rem
    }

    .gridNav .subTitle .navArrows span {
        margin-left: 0;
        padding: 7px;
    }

    .gridNav .subTitle .navArrows .previous,
    .gridNav .subTitle .navArrows .next {
        border-left: 0px;
    }
}

.cardInfo .badgeContainer {
    flex-grow: 1;
}

.cardInfo .badge {
    margin-left: auto;
    margin-right: 1rem;
}

@media (max-width: 500px) {
    .articleCard:hover {
        border: 1px solid var(--border);
    }

    .articleCard .articleTitle {
        font-size: 1.4rem;
        font-weight: 800;
    }

    .articleCard .articleDescription {
        line-height: 2rem;
    }

    .articleCard .cardBody {
        padding-top: 1.6rem;
    }

    .articleCard {
        height: 230px;
    }
}