.randUser{
    margin: 0px;
    transform: rotate(90deg);
    justify-self: center;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}
.randUser .face {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    font-weight: 600;
    border: 2px solid var(--dark);
}

.randUser {
    --light-blue: rgb(194, 221, 255);
    --dark-blue: rgb(16, 70, 135);

    --light-purple: rgb(241, 207, 255);
    --dark-purple: rgb(110, 25, 146);

    --light-green: rgb(228, 255, 198);
    --dark-green: rgb(72, 120, 21);

    --light-orange: rgb(255, 218, 173);
    --dark-orange: rgb(199, 117, 16);

    --light-yellow: rgb(255, 240, 165);
    --dark-yellow: rgb(156, 133, 20);

    --light-red: rgb(255, 192, 192);
    --dark-red: rgb(174, 27, 27);
}


.randUser .yellow{
    background-color: var(--light-yellow);
    color: var(--dark-yellow);
    border: 2px solid var(--dark-yellow);
}
.randUser .orange{
    background-color: var(--light-orange);
    color: var(--dark-orange);
    border: 2px solid var(--dark-orange);
}
.randUser .red{
    background-color: var(--light-red);
    color: var(--dark-red);
    border: 2px solid var(--dark-red);
}
.randUser .green{
    background-color: var(--light-green);
    color: var(--dark-green);
    border: 2px solid var(--dark-green);
}
.randUser .blue{
    background-color: var(--light-blue);
    color: var(--dark-blue);
    border: 2px solid var(--dark-blue);
}
.randUser .purple{
    background-color: var(--light-purple);
    color: var(--dark-purple);
    border: 2px solid var(--dark-purple);
}