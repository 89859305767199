.landingHeroSection {
    margin-top: 60px;
    background-color: var(--deep-blue);
    color: var(--consoleWhite);
    padding: 20px;
    position: relative;
    clip-path: polygon(0 0,
            100% 0,
            100% 100%,
            0 calc(100% - 5vw));
}

.landingHeroSection .heroTitle ::-moz-selection {
    padding: 5px;
    background: var(--deep-dark-blue);
}

.landingHeroSection .heroTitle ::selection {
    padding: 5px;
    background: var(--deep-dark-blue);
    width: fit-content;
}

.landingHeroSection .secondTitle {
    text-align: center;
    max-width: 600px;
    font-size: 1.8rem;
    margin-bottom: 2rem;
}

.landingHeroSection .landingContainer {
    padding-top: 2rem;
    padding-bottom: 4.6rem;
}

.landingHeroSection .bigTitle {
    font-size: 4.5rem;
    margin-bottom: 0px;
}

.heroTitle {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.landingHeroSection .buttons {
    width: fit-content;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    margin-bottom: 2rem;
}

.landingHeroSection .buttons button {
    padding: 1rem;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    border: 0px solid var(--dark);
}

.joinButton {
    background-color: var(--gold);
    color: var(--dark);
}

.joinButton:hover {
    background-color: var(--gold-hover);
}

.darkTitle {
    background-color: var(--deep-dark-blue);
    padding-left: 1rem;
    padding-right: 1rem;
}

@media (max-width: 600px) {
    .landingHeroSection .bigTitle {
        font-size: 4rem;
        display: none;
    }

    .landingHeroSection .secondTitle {
        font-size: 1.4rem;
        background-color: var(--deep-dark-blue);
        padding: 1.6rem;
    }

    .landingHeroSection .landingContainer {
        padding-top: 0rem;
        padding-bottom: 1rem;
    }
}