.talkingHead{
    display: flex;
    background-color: var(--lightest);
    margin: auto;
    width: fit-content;
    padding: 10px;
    border-radius: 3px;
}
.talkingHead .icon{
    display: flex;
    justify-content: center;
}
.face{
    white-space: nowrap;
}
.quote p{
    line-height: 15px;
    margin-left: 1rem;
}
.quote p{
    line-height: 25px;
}