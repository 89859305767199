.PagerFeed .talkingHead {
    box-sizing: border-box;
    width: 100%;
}
.stackedHead .talkingHead{
    margin-bottom: 15px;
}
.PagerFeed{
    border: 1px solid var(--light-border);
    box-shadow: 5px 5px 5px rgb(226, 226, 226);
    padding: 15px;
}
.emptyMessage{
    padding: 15px;
}