.terminal-container{
    font-size: 16px;
    display: block;
    background-color: black;
    width: 100%;
    color: white;
}
.terminal-container input{
    box-sizing: border-box;
    padding: 2rem;
    padding-left: .5rem;
    padding-right:.5rem;
    font-family: "roboto";
    width: 100%;
    min-width: 0px;
    background-color: black;
    font-size: 20px;
    height: 22px;
    margin: 0px;
    border: 0px;
    color: rgb(26, 255, 186);
}
.terminal-container .term-line{
    margin-top: .2rem;
    margin-bottom: .2rem;
}
.term-cursor-marker{
    margin-left: 1rem;
}
.terminal-out{
    overflow-y: auto;
    height: 20rem;
    padding: 1rem;
    padding-top: 1.5rem;
}
.terminal-out pre {
    white-space: pre-wrap;
}
.terminal-in {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: rgb(26, 255, 186);
    border: 1px solid black;
    border-top: 1px solid white;
}
.terminal-in:hover{
    border: 1px solid rgb(26, 255, 186);
}
.terminal-container ::-moz-selection { 
    background: rgb(157, 255, 226);
    color: black;
}  
.terminal-container ::selection {
    background: rgb(157, 255, 226);
    color: black;
}