.table{
    color: var(--text);
    background-color: white;
    width: fit-content;
    border-radius: 10px;
    display: inline-block;
    box-shadow: -5px 0px 20px 1px  rgb(226, 226, 226);
    width: 400px;
}
.table:hover {
    transform: rotate(0turn);
}
.offsetTable {
    margin-left: -250px;
}
.tableBody {
    padding: 2rem;
    padding-top: 1rem;
    overflow-x: auto;
}
.tableTitle {
    padding: 2rem;
    padding-bottom: .5rem;
    font-weight: 700;
}
.tableHeader{
    color: var(--second);
    font-weight: 700;
}
.table h2 {
    display: flex;
    align-items: center;
    margin: 0px;
}
.table h2 span {
    margin-right: 1rem;
}
.tableFields {
    padding: 2px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
}
.tableFields:hover {
    cursor: pointer;
    background-color: rgb(235, 235, 235);
}
.activeField {
    background-color: rgb(235, 235, 235);
}
.tableSet{
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    overflow-y: auto;
}
.tableSet.table:not(:first-child) {
    margin-left: -10px;
}

.placeholderTable .tableFields:hover, .placeholderTable .activeField, .tableHeader:hover {
    background-color: white;
    cursor: default;
}
.placeholderTable{
    border-radius: 10px;
}