.aboutContainer {
    padding: 1rem;
}
.aboutContainer .brand {
    font-size: 3rem;
    color: var(--text);
    padding: 1rem;
    /* box-shadow: 5px 5px 20px rgba(0, 0, 0, .1); */
    border: 2px solid var(--border);
    /* border-radius: 5px; */
}
.aboutContainer .brand .brandText{
    align-self: flex-end;
    padding-bottom: .8rem;
}
.aboutContainer img {
    width: 6rem;
    margin-right: 1rem;
}

.aboutPage .infoCard{
    margin-bottom: 2rem;
}
@media (max-width: 500px){
    .aboutContainer .brand {
        font-size: 2.4rem;
        
    }
    .aboutContainer img {
        width: 5rem;
    }
    .aboutContainer .brand .brandText{
        padding-bottom: .5rem;
    }
    .aboutContainer .aboutContainer {
        padding: 0px;
    }
}