
.grid-nav button {
    color: rgb(160,160,160);
    margin-left: .5rem;
    background-color: rgb(242, 242, 242);
    border: 1px solid var(--border);
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
}
.grid-nav button:hover {
    border: 1px solid var(--main);
    color: var(--main);
}

.grid-nav button.disabled {
    color: var(--mid-border);
    cursor: default;
    border: 1px solid var(--mid-border);
}
.grid-nav button.disabled:hover {
    color: var(--mid-border);
    cursor: default;
    border: 1px solid var(--mid-border);
}
.grid-nav .previous {
    transform: rotate(180deg);
}
.grid-nav .buttons {
    display: flex;
}
.author-title{
    border: 1px solid var(--border);
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
}
.author-title .title{
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1rem;
    font-size: 2rem;
    font-weight: 900;
}
.author .articles {
    margin-top: 1rem;
}
.filtered .author-title {
    border: 1px solid var(--dark-yellow);
    color: var(--dark-yellow);
}

@media (max-width: 500px){
    .grid-nav .sub-title {
        display: block;
    }
    .grid-nav .buttons {
        margin-top: 1rem;
        justify-content: end;
    }
}