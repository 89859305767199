/* ABOUT */
.about h2 {
    display: flex;
    font-weight: 700;
}

.about {
    margin-top: 2rem;
    padding: 2rem;
    padding-bottom: 4rem;
    /* background-color: rgb(255, 252, 242); */
    background-color: var(--light-blue);
}

.about span {
    font-weight: 700;
    font-size: 2rem;
    margin-right: 1rem;
}

.aboutText {
    max-width: 800px;
    margin: auto;
}

.aboutExplanation,
.aboutSignature {
    line-height: 2.4rem;
    color: var(--dark-blue);
}

.aboutSignature {
    margin-top: 2rem;
}

.about .subTitle {
    /* color: rgb(198, 164, 52); */
    color: var(--dark-blue);
}

.aboutSignature {
    padding: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: rgb(247, 240, 218);
    background-color: var(--mid-light-blue);
    color: var(--dark-blue);
    border: 1px solid var(--dark-blue)
}

@media (max-width: 950px) {
    .about {
        padding: 1rem;
        padding-bottom: 2rem;
    }
}

@media (max-width: 550px) {

    .aboutExplanation,
    .aboutSignature {
        line-height: 2.2rem;
        margin-top: 1rem;
    }
}