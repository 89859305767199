.navContainer{
    position: fixed;
    top:0;
    left:0;
    right: 0;
    z-index: 100;
    height: 60px;
    transform: translate3d(0, 0, 200px);
}
.navBar {
    box-sizing: border-box;
    display: flex;
    background-color: var(--paper);
    height: 60px;
    border-bottom: 1px solid var(--border);
    border-bottom: 1px solid rgb(170,170,170);
}
.navBar a {
    color: grey;
}
.navBar a:hover{
    color:var(--text)
}
.brand img{
    width: 3.5rem;
    margin-right: .5rem;
}
.navBar .container {
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-grow: 1;
    max-width: 100%;
    background-color: var(--paper);
    z-index: 1;
    margin: 0px;
    align-items: center;
}
.navBar  button {
    font-weight: 600;
    background-color: rgb(248,248,248);
    border: 1px solid var(--border);
    color: var(--text)
}
.navBar  button:hover {
    color: var(--blue-dark);
    background-color: var(--blue-dark);
    color: var(--consoleWhite);
    border: 1px solid var(--blue-dark);

}

.navLeft, .navRight, .hamburger {
    display: flex;
    height: 40px;
}

.brand{
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.brand a {
    color: var(--text);
}
.brand a:hover{
    color: var(--dark);
}
.logo{
    display: flex;
    align-items: center;
}
.navBar ul {
    list-style: none;
    display: flex;
    align-items: center;
}
.navBar li{
    margin-right: 2rem;
}
.hamburger{
    display: none;
}
.hamburger .material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 1.8rem;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
}

.hamburger ul, .navRight ul {
    padding: 0px;
}
.hamburger li{
    display: flex;
    margin-right: 0px;
}
.hamToggle{
    cursor: pointer;
    background-color: rgb(240,240,240);
    padding: .2rem;
    border-radius: 5px;
}
.navRight .active{
    color: var(--text);
}
.dropNav{
    z-index: 0;
    display: none;
    overflow: hidden;
    transform: translateY(-100%);
    transition: transform .3s;
    background-color: var(--background);
    background-color: var(--mid-blue);
}
.dropNav.open {
    transform: translateY(0%);
}
.dropNavButton{
    font-size: 20px;
    cursor: pointer;
    padding: 1.5rem;
    color: var(--dark-blue);
    border-bottom: 1px solid var(--dark-blue)

}
.dropNavButton a{
    font-size: 1.8rem;
}
.dropNavButton a:hover{
    color: var(--dark-blue);
}
.dropNavButton:hover{
    background-color: var(--mid-dark-blue);
}

@media (max-width: 850px){
    .navRight{
        display: none;
    }
    .hamburger{
        display: flex;
    }
    .dropNav{
        display: block;
    }
}

@media (max-width: 500px) {
    .hamburger .material-symbols-outlined {
        font-size: 2.2rem;
    }
}