.commentList ul{
   list-style: none;
   padding: 0px; 
   line-height: normal;
}
.comment .commentBody {
    width: 100%;
}
.comment .commentBodyBody{
    border-left: 1px solid var(--light-border);
    border-radius: 5px;
    padding: .5rem;
    padding-top: 0px;
}
.commentFeedback {
    display: flex;
    align-items: center;
    margin-top: .5rem;
    margin-bottom: .5rem;
    margin-left: .5rem;
}
.commentFeedback .postButton{
    margin-left: auto;
    border: 1px solid var(--light-border);
    color: var(--dark);
}

.subComments{
    margin-left: 3rem;
}
.commentBody {
    margin-bottom: 1rem;
}
.commentBodyFooter{
    border-bottom: 1px solid var(--light-border);
}
.comment .commentForm {
    margin-top: 1.5rem;
    margin-bottom: 0px;
    padding-left: .5rem;
    padding-bottom: .5rem;
    border-left: 1px solid var(--blue);
    border-bottom: 1px solid var(--blue);
}
.commentBodyHeader .author {
    font-size: 1.1rem;
    font-weight: 500;
}
.commentBodyHeader .author .date {
    font-size: .9rem;
    color: grey;
}
.commentFeedback button {
    background-color: inherit;
    border: 0px solid var(--border);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--blue-dark);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.commentFeedback button:hover{
    background-color: rgb(245,245,245);
}