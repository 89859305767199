
.tree-container  {
    box-sizing: border-box;
    width: 100%;
    height: 36rem;
    display: flex;
}
.tree-map {
    box-sizing: border-box;
    width: 100%;
    display: flex;
}
.tree-map svg{
    animation: sway 20s infinite linear;
    transform-style: preserve-3d;
    width: 100%
}
.tree-map svg rect:not(.mouse-hover) {
    opacity: .50;
}
.tree-map svg rect.mouse-hover {
    filter: brightness(150%);   
}
.tree-map svg.mouse-out rect:not(.mouse-hover) {
    opacity: 1;
}
.tree-map svg text {
    cursor: default;
}
.tree-map{
    --grid-blue-lightest: #7272e1;
    --grid-blue-light: #5a5ad6;
    --grid-blue-mid: #4242c0;
    --grid-blue-dark: #3131a9;
    --grid-blue-darkest: #2a2a99;
}
@keyframes sway {
    0% { transform: rotate3d(0, 1, 0.1, -35deg); }
    50% { transform: rotate3d(0, 1, 0.1, 35deg); }
    100% { transform: rotate3d(0, 1, 0.1, -35deg); }
}