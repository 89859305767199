.treeLandingContainer{
    display: grid;
    grid-template-columns: 1fr 2fr;
    color: var(--consoleWhite);
}
.treeMap  {
    padding-left: 4rem;
    padding-right: 4rem;
    box-sizing: border-box;
    width: 100%;
    height: 40rem;
    display: flex;
    background-color: var(--darkPaper);
}
.treeContainer {
    box-sizing: border-box;
    width: 100%;
    display: flex;
}

.treeLandingContainer .titleOne {
    font-size: 5rem;
    margin-top: 0px;
    margin-bottom: .5rem;
}
.treeLandingContainer  .titleTwo, .treeLandingContainer .titleThree {
    padding: 0px;
    color: rgb(200, 200, 200);
    z-index: 5;
}
.treeOverview {
    padding: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    box-shadow: 5px 5px 40px rgba(129, 87, 255, .5);
}
.treeOverview p {
    font-family: Roboto Mono;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--consoleWhite);
}
.treeLandingContainer .titleOne span{
    background-color: rgb(129, 87, 255);
}
.treeTitles{
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.treeMapModes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}
.treeCard{
    background-color: var(--darkSurfacePaper);
    padding: 1rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-radius: 2px;
    min-height: 25rem;
    /* box-shadow: 5px 5px 20px rgba(129, 87, 255, .1); */
}
.treeCard h2{
    display: flex;
    align-items: center;
    color: var(--consoleWhite);
    margin-bottom: 0px;
}
.treeCard p{
    color: rgb(200, 200, 200);
}
.treeCard .cardIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    width: 25px;
    height: 25px;
    color: rgb(129, 87, 255);
    border-radius: 50%;
}
.treeCard .cardIcon span{
    font-weight: 900;
}

.treeMap svg{
    animation: spinner 20s infinite linear;
    perspective: 500px;
    transform-style: preserve-3d;
    width: 100%
}
.treeMap svg rect:not(.hover) {
    opacity: .25;
}
.treeMap svg rect.hover {
    filter: brightness(150%);   
}
.treeMap svg.out rect:not(.hover) {
    opacity: 1;
}

.treeMap svg text {
    cursor: default;
}
:root {
    /* --capFlag1: rgb(133, 99, 255); */
    --darkPaper: rgb(36, 36, 38);
    --darkSurfacePaper: rgb(46, 46, 47);
    /* --darkPaper: #242526; */
    --capFlag2: rgb(98, 55, 255);
    --capFlag3: rgb(77, 41, 207);
    --capFlag4: rgb(67, 39, 168);
    --capFlag5: rgb(56, 35, 132);
    --capFlag6: rgb(44, 30, 97);
}

.treeHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.treeHeader h2 {
    font-family: Roboto Mono;
    color: var(--consoleWhite);
    text-align: center;
    font-size: 3rem;
    margin-bottom: 0rem;
    padding-bottom: 0px;
    height: fit-content;
    align-self: flex-end;
}

.blurOne{
    z-index: 0;
    position: absolute;
    top: 600px;
    left: 275px;
    box-shadow: 0px 0px 175px 60px rgba(255, 223, 43, .4);
    background-color: rgba(255, 223, 43, .15);
    width: 2px;
    height: 2px;
    border-radius: 50%;
}

.treeMapPage form {
    max-width: 600px;
}
.treeMapPage input{
    background-color: var(--darkSurfacePaper);
    color: var(--consoleWhite);
}
.treeMapPage .emailForm h2, .treeMapPage .emailForm p {
    color: var(--consoleWhite);
}

.treeMapPage ::-moz-selection { 
    padding: 5px;
    background: var(--darklight);
  }
  
.treeMapPage ::selection {
    padding: 5px;
    background: var(--darklight);
    width: fit-content;
}
@keyframes spinner {
    0% { transform: rotate3d(0, 1, 0.2, -55deg); }
    50% { transform: rotate3d(0, 1, 0.2, 55deg); }
    100% { transform: rotate3d(0, 1, 0.2, -55deg); }
}

@media (max-width: 1500px){
    .blurOne{
        display: none;
    }
    .treeTitles .titleThree {
        margin-bottom: 6rem;
    }
    .treeLandingContainer{
        grid-template-columns: 1fr;
    }
    .treeTitles .titleOne, .treeTitles .titleThree {
        text-align: center;
        padding-left: 0px;
    }
}
@media (max-width: 1000px){
    .treeMapModes {
        display: grid;
        grid-template-columns: 1fr;
    }
    .treeMap  {
        padding-left: 0rem;
        padding-right: 0rem;
    }
}
@media (max-width: 600px){
    .treeLandingContainer .titleOne{
        font-size: 4rem;
    }
    .treeOverview {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .treeOverview p {
        margin-top: 0px;
    }
}