.hashForm{
    border: 1px solid var(--border);
    padding: 1rem;
    border-radius: 5px;
}
.hashForm .iconLabel {
    margin-right: 1rem;
    font-size: 1.8rem;
}
.hashForm button {
    width: 100%;
}
.hashForm .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    width: 6rem;
    height: 6rem;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, .2);
    border: 1px solid var(--light-border);
    border-radius: 50%;
    margin: auto;
    margin-bottom: 1rem;
}

.hash-icon {
    --light-blue: rgb(194, 221, 255);
    --dark-blue: rgb(16, 70, 135);

    --light-purple: rgb(241, 207, 255);
    --dark-purple: rgb(110, 25, 146);

    --light-green: rgb(228, 255, 198);
    --dark-green: rgb(72, 120, 21);

    --light-orange: rgb(255, 218, 173);
    --dark-orange: rgb(199, 117, 16);

    --light-yellow: rgb(255, 240, 165);
    --dark-yellow: rgb(156, 133, 20);

    --light-red: rgb(255, 192, 192);
    --dark-red: rgb(174, 27, 27);
}

.hash-icon{
    margin: 0px;
    transform: rotate(90deg);
    width: fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.icon-face {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: 2.8rem;
    font-weight: 800;
    border-radius: 50%;
}

.hash-icon .yellow{
    background-color: var(--light-yellow);
    color: var(--dark-yellow);
    border: 2px solid var(--dark-yellow);
}
.hash-icon .orange{
    background-color: var(--light-orange);
    color: var(--dark-orange);
    border: 2px solid var(--dark-orange);
}
.hash-icon .red{
    background-color: var(--light-red);
    color: var(--dark-red);
    border: 2px solid var(--dark-red);
}
.hash-icon .green{
    background-color: var(--light-green);
    color: var(--dark-green);
    border: 2px solid var(--dark-green);
}
.hash-icon .blue{
    background-color: var(--light-blue);
    color: var(--dark-blue);
    border: 2px solid var(--dark-blue);
}
.hash-icon .purple{
    background-color: var(--light-purple);
    color: var(--dark-purple);
    border: 2px solid var(--dark-purple);
}