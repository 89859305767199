code[class*="language-"],
pre[class*="language-"] {
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	color: var(--consoleWhite);
	background: var(--consoleBackground);
	font-family: Roboto Mono, monospace;
	font-size: 1em;
	line-height: 1.3em;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

code[class*="language-"]::-moz-selection,
pre[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection,
pre[class*="language-"] ::-moz-selection {
	background: var(--consoleHighlight);
	/* color: var(--consoleWhite); */
}

code[class*="language-"]::selection,
pre[class*="language-"]::selection,
code[class*="language-"] ::selection,
pre[class*="language-"] ::selection {
	background: var(--consoleHighlight);
	/* color: var(--consoleBackground); */
}

:not(pre)>code[class*="language-"] {
	white-space: normal;
	border-radius: 0.2em;
	padding: 0.1em;
}

pre[class*="language-"] {
	overflow: auto;
	position: relative;
	margin: 0rem 0;
	padding: 1.5em 1.5em;
	border: 0px solid var(--lightest);
	/* border-radius: 5px; */
}

.language-css>code,
.language-sass>code,
.language-scss>code {
	color: var(--consoleOrange);
}

[class*="language-"] .namespace {
	opacity: 0.7;
}

.token.atrule {
	color: var(--consoleOrange);
}

.token.attr-name {
	color: var(--consoleOrange);
}

.token.attr-value {
	color: var(--consoWhite);
}

.token.attribute {
	color: var(--consoleRed);
}

.token.boolean {
	color: var(--consoleRed);
}

.token.builtin {
	color: var(--consoleBlue)
}

.token.cdata {
	color: var(--consoleBlue)
}

.token.char {
	color: var(--consoleLightGreen)
}

.token.class {
	color: var(--consoleBlue)
}

.token.class-name {
	color: var(--consoleBlue)
}

.token.comment {
	color: var(--consoleComment)
}

.token.constant {
	color: var(--consoleOrangeRed)
}

.token.deleted {
	color: var(--consoleOrangeRed);
}

.token.doctype {
	color: var(--consoleOrange);
}

.token.entity {
	color: var(--consoleRed);
}

.token.function {
	color: var(--consoleBlue);
}

.token.hexcode {
	color: var(--consoleOrangeRed);
}

.token.id {
	color: var(--consolePurple);
	font-weight: bold;
}

.token.important {
	color: var(--consolePurple);
	font-weight: bold;
}

.token.inserted {
	color: var(--consoleBlue)
}

.token.keyword {
	color: var(--consolePurple)
}

.token.number {
	color: var(--consoleOrangeRed)
}

.token.operator {
	color: var(--consoleBlue)
}

.token.prolog {
	color: var(--consoleOrange)
}

.token.property {
	color: var(--consoleBlue)
}

.token.pseudo-class {
	color: var(--consoleOrangeRed);
}

.token.pseudo-element {
	color: var(--consoleRed);
}

.token.punctuation {
	color: var(--consoleWhite);
}

.token.regex {
	color: var(--consoleBlue);
}

.token.selector {
	color: var(--consoleRed);
}

.token.string {
	color: var(--consoleLightGreen);
}

.token.symbol {
	color: var(--consolePurple);
}

.token.tag {
	color: var(--consoleRed);
}

.token.unit {
	color: var(--consoleRed);
}

.token.url {
	color: var(--consoleRed);
}

.token.variable {
	color: var(--consoleBlue);
}