.modalContainer {
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalContainer.displayNone {
    display: none;
}
.modal {
    position: relative;
    padding: 2.6rem;
    width: 25rem;
    background-color: var(--paper);
    border-radius: 20px;
    box-sizing: border-box;
    max-width: 90%;
}
.modalHeader {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.modalFooter {
    margin-top: 1rem;
}
.modalHeader h2 {
    margin: 0px;
}
.modal .closeButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
}
.modalBody {
    color: var(--text);
    line-height: 1.8rem;
}
.modal .closeButton button {
    color: var(--dark);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: fit-content;
    width: 2.4rem;
    height: 2.4rem;
    border: 0px;
    border-radius: 50%;
    background-color: rgb(240,240,240);
}
.modal .closeButton button:hover {
    background-color: rgb(230,230,230);
}
.modalButtons {
    display: block;
}
.modalButtons .modalAffirm {
    margin-bottom: .6rem;
}
.modalButtons button {
    width: 100%;
    border-radius: 5px;
}