.searchbar{
    display: flex;
    margin-top: auto;
    border: 1px solid var(--border);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50px;
    height: 40px;
    box-sizing: border-box;
    background-color: rgb(240,240,240);
}
.searchbar input{
    transition: width .2s;
    /* font-size: 16px; */
    width: 100px;
    border-radius: 50px;
    height: 100%;
    margin: 0px;
    background-color: rgba(0,0,0,0);
    color: var(--text);
}
.searchbar input:focus{
    border: 0px;
    width: 200px;
}
.suggestions{
    display: none;
}
.searchbar .icon{
    font-size: 18px;
    color: var(--text);
    margin-top: auto;
    margin-bottom: auto;
    cursor: default;
}

@media (max-width: 600px){
    .searchbar input:focus{
        width: 150px;
    }
}