.contactForm .buttons button{
    margin-right: 1rem;
}
.contactForm .feedback {
    background-color: var(--light-blue);
    padding: 5px;
    color: var(--dark-blue);
    border: 1px solid var(--mid-blue);
}

@media (max-width: 400px){
    .contactForm .buttons{
        display: flex;
    }
}