.activePaths {
    background-color: var(--consoleBackground);
    font-family: Roboto Mono, monospace;
    padding: 1rem;
    box-shadow: 3px 3px 5px rgb(226, 226, 226);
    min-height: 2rem;
    overflow-y: auto;
    margin-bottom: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.activePaths ul {
    list-style-type: none;
    padding: 0; 
    margin: 0;
    color: var(--consoleWhite)
}
.activePaths li {
    white-space: nowrap;
    font-size: 1rem;
    border-radius: 10px;
    cursor: pointer;
} 

.activePaths li:hover {
    background-color: var(--consoleHighlight);
}   

.activePaths .active{
    background-color: var(--consoleHighlight);
}
.activePaths .pathInstructions:hover {
    background-color: var(--consoleBackground);
    cursor: default;
}
.activePaths .active:hover{
    background-color: var(--consoleHighlight);
}