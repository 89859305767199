.scrollRoutes .pageRouteBtn {
    cursor: pointer;
    display: block;
    border: 0px;
    padding: 0px;
    font-size: 1rem;
    margin-bottom: .7rem;
    color: grey;
}
.scrollRoutes .pageRouteBtn:hover {
    color: var(--blue-dark);
}
.scrollRoutes {
    box-sizing: border-box;
    max-height: 200px;
    padding-left: .7rem;
    height: fit-content;
    border-left: 1px solid var(--border);
    overflow-x: hidden;
    width: 200px;
    position:sticky;
    top: 130px;
    right: 0px;
    float: right;
    white-space: nowrap;
    margin-right: -255px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.interact{
    display: flex;
    width: 200px;
    position:sticky;
    top: 130px;
    left: 0px;
    margin-left: -255px;
    float: left;
}
.interact .badge {
    width: auto;
    text-align: center;
}
.interact .interactButtons {
    /* margin-top: 1rem; */
    margin-left: auto;
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: .5rem;
}

.interact .likeButton{
    margin-right: 0px;
    color: var(--blue-dark);
    border: 1px solid var(--blue-dark);
}
.interact .likeButton:hover {
    background-color: var(--blue-dark);
    color: var(--consoleWhite);
    border: 1px solid var(--blue-dark);
}
.scrollRoutes::-webkit-scrollbar {
  display: none;
}
.interact .interactButtons button{
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: rgba(251,251,251);
}

.interact .interactButtons .disabled{
    color: var(--light-border);
    border: 1px solid var(--light-border);
    cursor: default;
}
.interact .interactButtons .disabled:hover{
    color: var(--light-border);
    border: 1px solid var(--light-border);
}

.scrollRoutes h2 {
    font-size: 1.1rem;
    color: grey;
}
/* @media (max-width: 82rem){ */
@media (max-width: 1200px){
    .scrollRoutes {
        display: none;
    }
    .interact {
        display: none;
    }
}