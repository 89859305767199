form span {
    color: var(--text);
    font-size: 18px;
}

input,
textarea {
    box-sizing: border-box;
    margin-top: 5px;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    font-size: 18px;
    /* background-color: var(--field); */
    border: 0px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.article input,
.article textarea {
    border: 1px solid var(--border);
}

input {
    height: 52px;
}

input:focus,
textarea:focus {
    outline: none !important;
    border: 2px solid var(--second);
}

textarea {
    font-family: "roboto";
    line-height: 20px;
    min-height: 200px;
    max-height: 200px;
    padding: 15px;
}

form button {
    padding: 14px;
    padding-left: 30px;
    padding-right: 30px;
}

.footLink {
    width: fit-content;
    cursor: pointer;
    color: grey;
}

.footLink:hover {
    color: var(--second);
}

form label span {
    color: rgb(150, 150, 150)
}