.counterBadge{
    display: flex;
    align-items: center;
    color: var(--text);
    position: relative;
}
.counterBadge.bubble .count{
    position: absolute;
    right: -15px;
    top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 18px;
    height: 18px;
    padding: 2px;
    border-radius: 50%;
    color: var(--dark-green);
    font-weight: 600;
}
.counterBadge.medium span {
    font-size: 1.3rem;
}
.counterBadge.small span {
    font-size: 1.2rem;
}
.counterBadge.bubble .count span{
    margin: 0px;
    padding: 0px;
    font-size: 1.2rem;
}
.counterBadge.bubble .count {
    background-color: var(--green);
    border: 1px solid var(--green);
    color: var(--dark-green);
}
.counterBadge.flex {
    display: flex;
    gap: .4rem;
}